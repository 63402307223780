import { CeoScheduleInfo } from "../../system/types/ceoschedule";
import {
  ScheduleComponent,
  Month,
  Inject,
  EventRenderedArgs,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
import { useRef, useState } from "react";
import {
  CeoSchedulerInnerBox,
  CeoSchedulerInnerCircle,
  CeoSchedulerInnerTimeBox,
} from "../../styles/CeoScheduleStyle";
import { GRAYPALETITLE } from "../../styles/Color";
import { isoTimeFormatter } from "../Common/IsoDateFormatter";
import { InitCeoScheduleInfo } from "../../system/types/initObject";
import CeoScheduleDetailedModal from "./CeoScheduleDetailedModal";
import { ModalStyle, SmallModalStyle } from "../../styles/theme";
import { Box, Chip, Grid, Modal, Typography } from "@mui/material";
import { useUserState } from "../../system/context/UserContext";
import CeoScheduleEditModal from "./CeoScheduleEditModal";
import { addClass } from "@syncfusion/ej2-base";
import ReactDOM from "react-dom";
import AddIcon from "@mui/icons-material/Add";
interface CeoScheduleCalenderProps {
  data: CeoScheduleInfo[];
  refreshData: () => void;
  handleOpen: () => void;
  setSelectedDate: (item: Date | null) => void;
  selectedDate: Date | null;
}

function CeoScheduleCalendar({
  data,
  refreshData,
  handleOpen,
  setSelectedDate,
  selectedDate,
}: CeoScheduleCalenderProps) {
  const user = useUserState();
  const [modalType, setModalType] = useState("detail");
  let scheduleObj = useRef<ScheduleComponent | null>(null);
  const template = (props: any) => {
    if (props.IsAllDay) {
      return <CeoSchedulerInnerBox>{props.Subject}</CeoSchedulerInnerBox>;
    } else {
      return (
        <CeoSchedulerInnerTimeBox>
          <CeoSchedulerInnerCircle color={props.CategoryColor} />
          {props.Subject} ({isoTimeFormatter(props.StartTime)} ~{" "}
          {isoTimeFormatter(props.EndTime)})
        </CeoSchedulerInnerTimeBox>
      );
    }
  };

  function onEventRendered(args: EventRenderedArgs): void {
    let categoryColor: string = args.data.CategoryColor as string;

    if (!args.element || !categoryColor) {
      return;
    } else {
      if (args.data.IsAllDay) {
        args.element.style.border = "0px";
        args.element.style.borderRadius = "5px";
        args.element.style.backgroundColor = categoryColor;
        args.element.style.color = "#ffffff";
        args.element.style.cursor = "pointer";
        args.element.style.height = "21px";
        args.element.style.fontSize = "1.0rem";
        args.element.style.marginTop = "5px";
      } else {
        args.element.style.border = `1px solid ${categoryColor}`;
        args.element.style.borderRadius = "5px";
        args.element.style.color = GRAYPALETITLE;
        args.element.style.backgroundColor = "#ffffff";
        args.element.style.cursor = "pointer";
        args.element.style.height = "21px";
        args.element.style.fontSize = "1.0rem";
        args.element.style.fontWeight = "700";
        args.element.style.marginTop = "5px";
      }
    }

    if (
      user.employeeId === "20220914" ||
      user.employeeId === "20160107" ||
      user.employeeId === "20220317" ||
      user.employeeId === "20110601" ||
      user.employeeId === "20231106"
    ) {
      args.element.addEventListener("click", () => onClickRez(args.data));
      setModalType("edit");
    } else {
      args.element.addEventListener("click", () => onClickRez(args.data));
      setModalType("detail");
    }
  }

  function onDataBinding(e: any) {
    let items = e.result;
    let ScheduleData: any = [];

    if (items.length > 0) {
      for (var i = 0; i < items.length; i++) {
        ScheduleData.push({
          Id: items[i].scheduleId,
          Subject: items[i].scheduleSubject,
          StartTime: new Date(items[i].startTime),
          EndTime: new Date(items[i].endTime),
          IsAllDay: items[i].isAllDay,
          CategoryColor: items[i].categoryColor,
          Data: items[i],
        });
      }
    }

    e.result = ScheduleData;
  }
  const onPopupOpen = (args: any) => {
    args.cancel = true;
  };

  // 수정 모달
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  // 조회 모달

  const [openDetailPage, setOpenDetailPage] = useState(false);
  const handleDetailPageClose = () => setOpenDetailPage(false);
  const [selectedData, setSelectedData] =
    useState<CeoScheduleInfo>(InitCeoScheduleInfo);

  const onClickRez = (dataItem: any) => {
    setSelectedData(dataItem.Data);

    if (modalType === "detail") {
      setOpenDetailPage(true);
    } else {
      // edit
      setOpen(true);
    }
  };

  const getHoliday = (args: any) => {
    if (
      user.employeeId === "20220914" ||
      user.employeeId === "20160107" ||
      user.employeeId === "20220317" ||
      user.employeeId === "20110601" ||
      user.employeeId === "20231106"
    ) {
      return (
        <div className="testClass">
          <Chip
            variant="outlined"
            color="info"
            label="추가"
            size="small"
            style={{ cursor: "pointer" }}
            icon={<AddIcon />}
            onClick={() => {
              handleOpen();
              setSelectedDate(args.date);
            }}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const onRenderCell = (args: any) => {
    if (args.elementType === "monthCells") {
      addClass([args.element], "raffleperiod");
      let ele = document.createElement("div");
      ReactDOM.render(getHoliday(args), ele);
      args.element.appendChild(ele);
    }
  };

  return (
    <div>
      <ScheduleComponent
        currentView="Month"
        eventSettings={{
          dataSource: data,
          template: template as any,
        }}
        ref={scheduleObj}
        eventRendered={onEventRendered}
        readonly={true}
        dataBinding={onDataBinding}
        locale="ko"
        rowAutoHeight={true}
        popupOpen={onPopupOpen}
        renderCell={onRenderCell}
        height="1100px"
      >
        <ViewsDirective>
          <ViewDirective option="Month" />
        </ViewsDirective>
        <Inject services={[Month]} />
      </ScheduleComponent>
      <Modal
        open={openDetailPage}
        onClose={handleDetailPageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={SmallModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <CeoScheduleDetailedModal
              data={selectedData}
              handleClose={handleDetailPageClose}
            />
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <CeoScheduleEditModal
              handleClose={handleClose}
              detailedData={selectedData}
              refreshData={refreshData}
              selectedDate={selectedDate}
            />
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default CeoScheduleCalendar;
