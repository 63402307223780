import { styled } from "@mui/material/styles";
import {
  GRAYPALECONTENTS,
  GRAYPALETITLE,
  PRIMARY,
  REDBORDER,
  REDSTROKE,
} from "./Color";
import { Switch, SwitchProps } from "@mui/material";

export const CeoScheduleMainBox = styled("div")(() => ({
  maxWidth: "1620px",
}));
export const CeoScheduleButtonBox = styled("div")(() => ({
  margin: "0px",
  textAlign: "right",
}));
export const CeoScheduleBox = styled("div")(() => ({
  margin: "10px 0px",
}));

export const CeoScheduleModalContentBox = styled("div")(() => ({
  marginBottom: "10px",
}));

export const CeoScheduleModalContentFlexBox = styled("div")(() => ({
  marginBottom: "15px",
  display: "flex",
  alignItems: "flex-start",
}));

export const CeoScheduleModalContentTitle = styled("div")(() => ({
  fontWeight: "700",
  color: GRAYPALETITLE,
}));

export const CeoScheduleModalContent = styled("div")(() => ({
  marginLeft: "20px",
}));
export const CeoScheduleModalInput = styled("div")(() => ({
  width: "80%",
  marginLeft: "10px",
}));
export const CeoScheduleModalLabel = styled("div")(() => ({
  marginLeft: "10px",
}));

export const InbodySwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? REDSTROKE : PRIMARY,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: PRIMARY,
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const ColorBigBox = styled("div")(() => ({
  width: "18rem",
  display: "flex",
  flexWrap: "wrap",
}));

export const ColorBox = styled("div")(() => ({
  width: "2rem",
  height: "2rem",
  cursor: "pointer",
}));

export const ColorExample = styled("span")(({ color }: any) => ({
  color: "#ffffff",
  height: "1.5rem",
  borderRadius: "5px",
  textAlign: "center",
  padding: "0px 5px",
  backgroundColor: `${color}`,
}));

export const ColorExample2 = styled("span")(({ color }: any) => ({
  color: GRAYPALETITLE,
  border: `1px solid ${color}`,
  height: "1.5rem",
  borderRadius: "5px",
  textAlign: "center",
  padding: "0px 5px",
  fongtWeight: 700,
  display: "flex",
  alignItems: "center",
}));

export const OpenColorBoxText = styled("div")(() => ({
  color: GRAYPALECONTENTS,
  cursor: "pointer",
}));

export const CeoSchedulerInnerBox = styled("div")(() => ({
  paddingLeft: "10px",
}));

export const CeoSchedulerInnerTimeBox = styled("div")(() => ({
  paddingLeft: "5px",
  display: "flex",
}));

export const CeoSchedulerInnerCircle = styled("div")(({ color }: any) => ({
  border: `1px solid ${color}`,
  backgroundColor: `${color}`,
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  margin: "auto 5px",
}));

export const CeoSchedulerPreContent = styled("pre")(() => ({
  fontSize: "1rem",
  overflow: "auto",
  whiteSpace: "pre-wrap",
}));
