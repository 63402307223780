import { useRecoilState } from "recoil";
import { CeoAtom } from "../../system/atom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import {
  CeoScheduleButtonBox,
  CeoScheduleMainBox,
  CeoScheduleBox,
} from "../../styles/CeoScheduleStyle";
import { CustomButtonWhite, ModalStyle } from "../../styles/theme";
import { useUserState } from "../../system/context/UserContext";
import CeoScheduleCalendar from "./CeoScheduleCalender";
import { Box, Modal, Typography } from "@mui/material";
import CeoScheduleEditModal from "./CeoScheduleEditModal";
import { CeoScheduleInfo } from "../../system/types/ceoschedule";
import { InitCeoScheduleInfo } from "../../system/types/initObject";
import { CeoScheduleApi, ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";

function CeoScheduler() {
  const user = useUserState();
  const [ceoSchedulerOwner, setCeoSchedulerOwner] = useRecoilState(CeoAtom);
  const [data, setData] = useState<CeoScheduleInfo[]>([]);
  const [detailedData, setDetailedData] =
    useState<CeoScheduleInfo>(InitCeoScheduleInfo);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCeoSchedulerOwner(newValue);
  };
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  // 모달
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedDate(null);
  };

  const refreshData = () => {
    CeoScheduleApi.GetCeoScheduleList()
      .then((res) => {
        if (ceoSchedulerOwner === "회장님") {
          setData(
            res.data.filter((employee) => employee.scheduleOwner === "차기철")
          );
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  };

  useEffect(() => {
    refreshData();
  }, [ceoSchedulerOwner]);

  return (
    <CeoScheduleMainBox>
      <Tabs value={ceoSchedulerOwner} onChange={handleChange}>
        <Tab label="회장님 스케줄" value={"회장님"} />
      </Tabs>
      {(user.employeeId === "20220914" ||
        user.employeeId === "20160107" ||
        user.employeeId === "20220317" ||
        user.employeeId === "20110601" ||
        user.employeeId === "20231106") && (
        <CeoScheduleButtonBox>
          <CustomButtonWhite onClick={handleOpen}>일정 생성</CustomButtonWhite>
        </CeoScheduleButtonBox>
      )}

      <CeoScheduleBox>
        <CeoScheduleCalendar
          data={data}
          refreshData={refreshData}
          handleOpen={handleOpen}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
        />
      </CeoScheduleBox>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <CeoScheduleEditModal
              handleClose={handleClose}
              detailedData={detailedData}
              refreshData={refreshData}
              selectedDate={selectedDate}
            />
          </Typography>
        </Box>
      </Modal>
    </CeoScheduleMainBox>
  );
}

export default CeoScheduler;
